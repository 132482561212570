<template>
  <div class="device-visualization">
    <OrderType :data="orderTypeDatas" :total="orderTotal" />
    <OrderStatus :data="orderStatusDatas" :total="orderTotal" />
    <CountView :data="otherData" />
    <HistoryOrder/>
  </div>
</template>

<script>
import { DataModel } from "enn-schema-table";

import OrderType from "./OrderType.vue";
import OrderStatus from "./OrderStatus.vue";
import CountView from "./CountView.vue";
import HistoryOrder from "./HistoryOrder.vue";

export default {
  components: {
    OrderType,
    OrderStatus,
    CountView,
    HistoryOrder,
  },
  data() {
    const statisticsMD = new DataModel({
      getApi: "/api/v1/order/dashboard",
    });
    return {
      statisticsMD,
      orderTypeDatas: [],
      orderStatusDatas: [],
      orderTotal: 0,
      otherData: {},
      statistics: {},
    };
  },
  mounted() {
    this.getStatistics();
    // this.getOrderHistory();
  },
  methods: {
    async getStatistics() {
      const res = await this.statisticsMD.get();
      if (!res) {
        return;
      }
      const { order, product, workflow } = res;
      const { trialProduceCount = 0, productionCount = 0, testCount = 0 } = order || {};
      // 订单类型
      this.orderTypeDatas = [trialProduceCount, productionCount, testCount];
      this.orderTotal = order.totalCount;
      // 当前订单状态
      const { goingCount = 0, recentlyCount = 0, finishCount = 0 } = order || {};
      this.orderStatusDatas = [goingCount, recentlyCount, finishCount];
      // 其它
      this.otherData = {
        product: product.productCount,
        workflow: workflow.workflowCount,
      };
    },
  },
};
</script>

<style lang="less">
.device-visualization {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-gap: 20px;
  .card-box {
    padding: 20px;
    background-color: #fff;
    border-radius: 4px;
    .card-title {
      font-size: 18px;
      color: rgba(76, 76, 76, 1);
    }
  }
}
</style>
