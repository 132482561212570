<template>
  <div class="order-status-box card-box">
    <div class="card-title">当前订单状态</div>
    <div class="cart-container">
      <div class="progress-item-box" v-for="(item, i) of progressList" :key="i">
        <div class="progress-header">
          <div class="progress-title">
            {{ item.label }}
          </div>
          <div class="progress-num">
            {{ item.count }}
          </div>
        </div>
        <el-progress
          class="order-status-progress"
          :percentage="item.percent"
          :stroke-width="16"
          :show-text="false"
          :color="item.color"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    progressList() {
      const list = [
        {
          label: "未完成",
          count: 16,
          percent: 16,
          color: "rgba(0, 110, 187, 1)",
        },
        {
          label: "新增",
          count: 5,
          percent: 5,
          color: "rgba(249, 190, 0, 1)",
        },
        {
          label: "已完成",
          count: 23,
          percent: 23,
          color: "rgba(110, 185, 44, 1)",
        },
      ];
      const { total, data } = this;
      return list.map((item, i) => {
        const count = data[i];
        const percent = count / total;
        return {
          ...item,
          count: count,
          percent: percent <= 100 ? percent : 100,
        };
      });
    },
  },
};
</script>

<style lang="less">
.order-status-box {
  grid-column: span 3;
  height: 249px;
  .cart-container {
    padding-top: 31px;
    .progress-item-box {
      margin-bottom: 20px;
      .progress-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        color: rgba(51, 51, 51, 1);
        line-height: 20px;
        .progress-title {
          font-size: 12px;
          font-weight: 400;
        }
        .progress-num {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
