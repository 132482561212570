<template>
  <div class="count-view-box">
    <div class="link-products card-box">
      <div class="card-title">关联产品</div>
      <div class="card-container">
        <div class="number">{{ data.product || 0 }}</div>
        <img :src="productIcon" class="icon" />
      </div>
    </div>
    <div class="current-process card-box">
      <div class="card-title">当前制程</div>
      <div class="card-container">
        <div class="number">{{ data.workflow || 0 }}</div>
        <img :src="progressIcon" class="icon" />
      </div>
    </div>
  </div>
</template>

<script>
import productIcon from "./icon-link-product.png";
import progressIcon from "./icon-progress.png";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      productIcon,
      progressIcon,
    };
  },
};
</script>

<style lang="less">
.count-view-box {
  grid-column: span 3;
  .card-box + .card-box {
    margin-top: 13px;
  }
  .card-title {
    margin-bottom: 26px;
  }
  .card-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .number {
      font-size: 40px;
      font-weight: 500;
      line-height: 48px;
      color: rgba(76, 76, 76, 1);
    }
    .icon {
      width: 40px;
      font-size: 40px;
      color: rgba(85, 85, 85, 1);
    }
  }
}
</style>
