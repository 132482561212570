<template>
  <div class="order-type-box card-box">
    <div class="card-title">订单类型</div>
    <div class="card-container">
      <enn-pie :datas="datas" :options="options" :style="chartStyle" @init="onChartInit"></enn-pie>
      <div class="chart-legend">
        <div
          class="legend-item"
          :class="{ active: index === i }"
          v-for="(it, i) of types"
          :key="it.label"
          @click="onTypeClick(it, i)"
        >
          {{ it.label }}：{{ it.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      chartStyle: {
        width: "100%",
        height: "13vw",
      },

      options: {
        color: ["rgba(0, 110, 187, 1)", "rgba(232, 232, 232, 1)"],
        legend: {
          show: false,
        },
        series: {
          type: "pie",
          emphasis: {
            itemStyle: {
              color: "inherit",
            },
          },
        },
      },
      index: 0,
      pie: null,
      preTime: Date.now(),
    };
  },
  mounted() {
    this.animation = requestAnimationFrame(this.handleAnimation.bind(this));
  },
  beforeDestroy() {
    cancelAnimationFrame(this.animation);
  },
  computed: {
    datas() {
      const val = this.data[this.index];
      return {
        x: ["已完成", "未完成"],
        data: [val, this.total - val],
      };
    },
    types() {
      const types = ["试产", "量产", "测试"];
      return types.map((label, i) => ({
        label: label,
        value: this.data[i] || 0,
      }));
    },
  },
  methods: {
    onChartInit(pie) {
      this.pie = pie;
      this.highlightPie();

      this.pie.on("mouseover", ({ dataIndex }) => {
        this.highlightPie(dataIndex);
      });
      this.pie.on("mouseout", () => {
        this.highlightPie();
      });
    },
    onTypeClick(it, i) {
      this.index = i;
    },
    highlightPie(dataIndex = 0) {
      this.pie.dispatchAction({
        type: "downplay",
        dataIndex: +!dataIndex,
      });
      this.pie.dispatchAction({
        type: "highlight",
        dataIndex: dataIndex,
      });
    },
    handleAnimation() {
      this.animation = requestAnimationFrame(this.handleAnimation.bind(this));
      if (Date.now() - 5000 < this.preTime) {
        return;
      }
      this.preTime = Date.now();
      this.index = (this.index + 1) % this.types.length;
    },
    setOptions() {
      const options = {};
      this.options = options;
    },
  },
};
</script>

<style lang="less">
.order-type-box {
  grid-column: span 5;
  height: 249px;
  .card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
  .chart-legend {
    padding-left: 40px;
    .legend-item {
      position: relative;
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: rgba(76, 76, 75, 1);
      cursor: pointer;
      &.active {
        font-size: 28px;
        line-height: 32px;
        color: rgba(0, 110, 187, 1);
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: -22px;
          transform: translateY(-50%);
          border-top: 7px solid transparent;
          border-bottom: 7px solid transparent;
          border-left: 8px solid rgba(0, 110, 187, 1);
        }
      }
    }
  }
}
</style>
