<template>
  <div class="history-order-box card-box">
    <div class="card-title">历史订单统计</div>
    <enn-bar
      :style="chartStyle"
      :datas="datas"
      :options="options"
      @init="onChartInit"
    />
  </div>
</template>

<script>
import { DataModel } from "enn-schema-table";

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const orderHistoryMD = new DataModel({
      getApi: "/api/v1/order/history",
      getMap(res) {
        delete res.message;
        delete res._message;
        return res;
      },
    });

    return {
      orderHistoryMD,
      chartStyle: {
        width: "calc(100%-40px)",
        height: "345px",
      },
      datas: {
        name: ["新增", "已完成", "未完成"],
        x: [],
        data: [],
      },
      options: {
        color: [
          "rgba(249, 190, 0, 1)",
          "rgba(110, 185, 44, 1)",
          "rgba(229, 76, 42, 1)",
        ],
        legend: {
          left: "auto",
          right: 20,
          itemWidth: 12,
          itemHeight: 12,
          textStyle: {
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "none",
          },
          borderColor: "transparent",
          textStyle: {
            fontSize: 12,
          },
          position: "top",
          formatter({ color, seriesName, value }) {
            return `<div class="history-tooltip-icon" style="background-color:${color};"></div>${seriesName}：${value}`;
          },
        },
        xAxis: {},
        series: [
          { type: "bar", barWidth: 21 },
          { type: "bar", barWidth: 21 },
          { type: "bar", barWidth: 21 },
        ],
      },
      chart: null,
    };
  },

  mounted() {
    this.getHistory();
  },
  methods: {
    onChartInit(chart) {
      this.chart = chart;
    },
    async getHistory() {
      const res = await this.orderHistoryMD.get();
      if (res) {
        const x = [];
        const data = [[], [], []];

        Object.keys(res).forEach((key) => {
          x.push(key);
          const item = res[key];
          data[0].push(item.newCount);
          data[1].push(item.finishCount);
          data[2].push(item.goingCount);
        });

        this.datas.x = x;
        this.datas.data = data;
      }
    },
  },
};
</script>

<style lang="less">
.history-order-box {
  grid-column: span 11;
  position: relative;
  height: 385px;
  box-sizing: border-box;
  .card-title {
    position: absolute;
  }
  .history-tooltip-icon {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 6px;
  }
}
</style>
